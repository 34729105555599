import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="24"
      height="24"
      viewBox="0 0 660 660"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path d="m 230,0 0,230 -230,0 0,200 230,0 0,230 200,0 0,-230 230,0 0,-200 -230,0 0,-230 -200,0 z" />
        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
